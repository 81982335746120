import dayjs from "dayjs";
export function secondsToHoursMinutesSeconds(seconds) {
    const hours = Math.floor(seconds / 3600);
    const remainingSecondsAfterHours = seconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60);
    const remainingSeconds = remainingSecondsAfterHours % 60;
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}
// 以下方法会为时间添加00：开头
export function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const hoursString = hours > 0 ? hours.toString().padStart(2, '0') : '00';
    return `${hoursString}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
}
//从文件名中提取日期
export function getDateFromFile(fileName) {
    const match = fileName.match(/录制-\S*-(\d{8})-\S*\.xml/);
    if (match && match[1]) {
        // 提取到的日期
        const dateStr = match[1];
        // 使用 dayjs 格式化日期
        const formattedDate = dayjs(dateStr, "YYYYMMDD").format("YYYY-MM-DD");
        console.log("提取日期:", formattedDate);
    }
}